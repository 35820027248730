import React, { useEffect } from "react";
import { Link } from "gatsby";

import { Menu } from "antd";
import { Emoji } from "emoji-mart";
const { SubMenu } = Menu;

import color from "~styles/color";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  GithubOutlined,
  SearchOutlined,
  CaretDownFilled as DownOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Launch } from "@material-ui/icons";
import AI2_THOR_LOGO from "~icons/ai2thor-white.svg";
import SiteHelmet from "~components/site-helmet";
import { DocSearch } from "~components/docsearch";

import GH_CODE from "~icons/code.svg";
import GH_ISSUES from "~icons/issues.svg";
import GH_RELEASES from "~icons/tags.svg";
import GH_DISCUSSIONS from "~icons/discussions.svg";
import { AiOutlineArrowRight } from "react-icons/ai";

function SubMenuIcon(props: { src: any }) {
  return (
    <img
      src={props.src}
      css={css`
        width: 14px;
        margin-right: 3px;
      `}
    />
  );
}

function SearchKeyboardKey(props: {
  keyboardKey: string;
  width?: number;
  isPressed?: boolean;
  marginRight?: string;
}) {
  return (
    <div
      css={css`
        width: ${props.width ? `calc(25px * ${props.width})` : "25px"};
        margin-right: ${props.marginRight};
        height: 25px;
        line-height: 20px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 13px;
        color: ${color.gray1};
        box-shadow: inset 0px -5px 6px ${props.isPressed ? color.gray7 : color.gray12};
        background-color: ${props.isPressed ? color.gray5 : color.gray9};
        text-align: center;
        display: inline-block;
      `}
    >
      {props.keyboardKey}
    </div>
  );
}

/**
 * Sets the horizontal and vertical navigation for each page on the website.
 * TODO: allow page to be passed in.
 */
class Navigation extends React.Component {
  state = {
    current: "",
  };

  handleClick = (e) => {
    this.setState({ current: e.key });
  };

  render() {
    const { current } = this.state;
    return (
      <span
        css={css`
          .ant-menu-item,
          .ant-menu-item a {
            background-color: transparent !important;
          }
        `}
      >
        <Menu
          onClick={this.handleClick}
          selectedKeys={[current]}
          mode="horizontal"
          css={css`
            background-color: transparent;
            color: white;
            border-bottom: none;
            width: 100%;

            @media (max-width: 1050px) {
              width: 40vw;
            }

            @media (max-width: 915px) {
              width: 30vw;
            }

            @media (max-width: 790px) {
              width: 50px;
            }

            @media (max-width: 690px) {
              margin-top: 35px;
              text-align: center;
              width: 100%;
            }

            .ant-menu-item,
            .ant-menu-item a {
              color: white;
              background-color: transparent !important;
            }

            .ant-menu-item a:hover,
            .ant-menu-item:hover,
            .ant-menu-item-active,
            .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
            .ant-menu-submenu-active,
            .ant-menu-submenu-title:hover,
            .ant-menu-item:hover,
            .ant-menu-submenu:hover,
            .ant-menu-item-open,
            .ant-menu-submenu-open,
            .ant-menu-item-selected,
            .ant-menu-submenu-selected {
              transition-duration: 0.15s;
              color: ${color.gray5} !important;
            }

            .ant-menu-item-group {
              padding-right: 10px !important;
            }

            .ant-menu-item-only-child,
            .ant-menu-submenu-horizontal {
              border-bottom: none !important;
            }

            .anticon {
              transition: none !important;
            }
          `}
        >
          <Menu.Item key="demo">
            <Link to="/demo">Demo</Link>
          </Menu.Item>
          <SubMenu
            key="iTHOR"
            title={
              <>
                iTHOR{" "}
                <DownOutlined
                  style={{
                    fontSize: "11px",
                    marginRight: "-10px",
                    opacity: 0.8,
                    marginLeft: "-2.5px",
                  }}
                />
              </>
            }
          >
            <Menu.Item key="iTHOR-overview">
              <Link to="/ithor">Overview</Link>
            </Menu.Item>
            <Menu.Item key="iTHOR-docs">
              <Link to="/ithor/documentation">API Reference</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="ManipulaTHOR"
            title={
              <>
                ManipulaTHOR{" "}
                <DownOutlined
                  style={{
                    fontSize: "11px",
                    marginRight: "-10px",
                    marginLeft: "-2.5px",
                    opacity: 0.8,
                  }}
                />
              </>
            }
          >
            <Menu.Item key="ManipulaTHOR-overview">
              <Link to="/manipulathor">Overview</Link>
            </Menu.Item>
            <Menu.Item key="ManipulaTHOR-docs">
              <Link to="/manipulathor/documentation">API Reference</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="RoboTHOR"
            title={
              <>
                RoboTHOR{" "}
                <DownOutlined
                  style={{
                    fontSize: "11px",
                    marginRight: "-10px",
                    marginLeft: "-2.5px",
                    opacity: 0.8,
                  }}
                />
              </>
            }
          >
            <Menu.Item key="RoboTHOR-overview">
              <Link to="/robothor">Overview</Link>
            </Menu.Item>
            <Menu.Item key="RoboTHOR-docs">
              <Link to="/robothor/documentation">API Reference</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="publications">
            <Link to="/publications">Publications</Link>
          </Menu.Item>
          <SubMenu
            key="challenges"
            title={
              <>
                Challenges{" "}
                <DownOutlined
                  style={{
                    fontSize: "10px",
                    marginLeft: "-2.5px",
                    marginRight: "-10px",
                    opacity: 0.8,
                  }}
                />
              </>
            }
          >
            <Menu.ItemGroup title={<span>CVPR 2022</span>}>
              <Menu.Item key="challenges-rearrange2022">
                <Link to="/rearrangement">Rearrangement</Link>
              </Menu.Item>
              <Menu.Item key="challenges-teach-cvpr2022">
                <a href="//teachingalfred.github.io/EAI22/" target="_blank">
                  TEACh{" "}
                  <Launch
                    style={{
                      fontSize: 12,
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginTop: "-3px",
                    }}
                  />
                </a>
              </Menu.Item>
              <Menu.Item key="challenges-alfred-cvpr2022">
                <a href="//askforalfred.com/EAI22/" target="_blank">
                  ALFRED{" "}
                  <Launch
                    style={{
                      fontSize: 12,
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginTop: "-3px",
                    }}
                  />
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title={<span>CVPR 2021</span>}>
              <Menu.Item key="challenges-rearrange2021">
                <Link to="/rearrangement-cvpr-2021">Rearrangement</Link>
              </Menu.Item>
              <Menu.Item key="challenges-objnav2021">
                <Link to="/robothor/cvpr-2021-challenge">ObjectNav</Link>
              </Menu.Item>
              <Menu.Item key="challenges-alfred-cvpr2021">
                <a href="//askforalfred.com/EAI21/" target="_blank">
                  ALFRED{" "}
                  <Launch
                    style={{
                      fontSize: 12,
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginTop: "-3px",
                    }}
                  />
                </a>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title={<span>CVPR 2020</span>}>
              <Menu.Item key="challenges-objnav2020">
                <Link to="/robothor/challenge">ObjectNav</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu
            key="github"
            title={
              <>
                <GithubOutlined
                  style={{
                    marginRight: "6px",
                    marginBottom: "3px",
                    verticalAlign: "middle",
                  }}
                />
                GitHub{" "}
                <DownOutlined
                  style={{
                    fontSize: "10px",
                    marginRight: "-5px",
                    marginLeft: "-2.5px",
                    opacity: 0.8,
                  }}
                />
              </>
            }
          >
            <Menu.Item
              key="github-code"
              style={{
                marginBottom: "-15px",
                marginTop: "0px",
              }}
            >
              <a href="//github.com/allenai/ai2thor" target="_blank">
                <SubMenuIcon src={GH_CODE} /> Code
              </a>
            </Menu.Item>
            <Menu.Item
              key="github-issues"
              style={{
                marginBottom: "-15px",
              }}
            >
              <a href="//github.com/allenai/ai2thor/issues" target="_blank">
                <SubMenuIcon src={GH_ISSUES} /> Issues
              </a>
            </Menu.Item>
            <Menu.Item
              key="github-discussions"
              style={{
                marginBottom: "-15px",
              }}
            >
              <a
                href="//github.com/allenai/ai2thor/discussions"
                target="_blank"
              >
                <SubMenuIcon src={GH_DISCUSSIONS} /> Discussions
              </a>
            </Menu.Item>
            <Menu.Item key="github-releases">
              <a href="//github.com/allenai/ai2thor/releases" target="_blank">
                <SubMenuIcon src={GH_RELEASES} /> Releases
              </a>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item key="blog">
            <Link to="/blog">Blog</Link>
          </Menu.Item> */}
        </Menu>
      </span>
    );
  }
}

export function Header(props: {
  useBoxShadow?: boolean;
  navigationMaxWidth?: string;
  includeSearch?: boolean;
}) {
  // support for keyboard shortcuts when searching
  useEffect(() => {
    let focusInput = (e: any) => {
      let docSearch = document.getElementById("doc-search");
      if (docSearch === null) {
        return;
      }
      if (e.key === "u" && e.ctrlKey === true) {
        e.preventDefault();
        e.stopPropagation();
        docSearch.focus();
      } else if (e.key === "Escape" && document.activeElement === docSearch) {
        docSearch.blur();
      }
    };
    document.addEventListener("keydown", focusInput);
    return () => document.removeEventListener("keydown", focusInput);
  });

  return (
    <>
      <header
        css={css`
          border-bottom: 1px solid ${color.gray9};
          background-color: rgba(0, 0, 0, 0.6);
          box-shadow: ${props.useBoxShadow === false
            ? "none"
            : "0px 0px 55px 4px rgba(0, 0, 0, 1)"};
          padding-top: 25px;
          padding-bottom: 25px;
        `}
      >
        <div
          css={css`
            max-width: ${props.navigationMaxWidth === undefined
              ? "1300px"
              : props.navigationMaxWidth};
            width: 100%;
            margin: auto;
          `}
        >
          <Link to="/">
            <img
              src={AI2_THOR_LOGO}
              css={css`
                @media (max-width: 690px) {
                  text-align: center;
                  width: 100%;
                  padding-left: 0px;
                }
                height: 30px;

                padding-left: ${props.navigationMaxWidth == undefined
                  ? "20px"
                  : "0px"};
              `}
            />
          </Link>
          {props.includeSearch === true ? (
            <div
              css={css`
                position: relative;
                color: white;
                display: inline-block;
                vertical-align: middle;
                margin-left: 120px;
                margin-top: -15px;
                margin-bottom: -15px;
                @media (max-width: 1300px) {
                  margin-left: 15px;
                }
                @media (max-width: 690px) {
                  margin-bottom: -20px;
                  margin-top: 20px;
                  display: block;
                }
              `}
            >
              <DocSearch />
              <input
                type="text"
                id="hidden-duplicate-search"
                css={css`
                  display: none;
                `}
              />
              <input
                type="text"
                id="doc-search"
                className="doc-search"
                placeholder="Search iTHOR"
                css={css`
                  width: 400px;
                  @media (max-width: 1250px) {
                    width: 300px;
                  }
                  @media (max-width: 690px) {
                    width: calc(100vw - 70px);
                  }
                  height: 40px;
                  border: 2px solid transparent;
                  padding-left: 35px;
                  border-radius: 5px;
                  background-color: ${color.gray11};
                  border: 1.5px solid ${color.dark.geekblue5};
                  border: 2px solid transparent;
                  transition-duration: 0.3s;
                  color: white;
                  &:focus {
                    border: 2px solid ${color.gray9};
                  }
                  &::placeholder {
                    color: ${color.gray4};
                    opacity: 0.7;
                  }
                `}
              />
              <SearchOutlined
                style={{
                  fontSize: "16px",
                  position: "absolute",
                  left: "13px",
                  top: "13.5px",
                  color: color.gray4,
                }}
              />
              <div
                css={css`
                  position: absolute;
                  right: 13px;
                  top: 7px;
                `}
              >
                <SearchKeyboardKey
                  keyboardKey="Ctrl"
                  width={1.75}
                  marginRight="3px"
                />
                <SearchKeyboardKey keyboardKey="U" />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            css={css`
              @media (min-width: 690px) {
                float: right;
              }
              margin-top: -10px;
            `}
          >
            <Navigation />
          </div>
        </div>
      </header>
      <a href="//procthor.allenai.org/" target="_blank">
        <div
          css={css`
            background: ${color.light.blue6 + "66"};
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            z-index: 99 !important;
            position: relative;
            color: white;
            transition-duration: 0.3s;
            border-bottom: 1px solid ${color.gray9};
            font-size: 16px;
            &:hover {
              background: ${color.light.blue6 + "88"};
            }

            .emoji-mart-emoji {
              display: inline-block;
              vertical-align: middle;
              margin-left: 2px;
            }
          `}
        >
          <div
            css={css`
              display: inline-block;
              font-size: 12px;
              background: ${color.light.blue3};
              color: ${color.gray2};
              border-radius: 5px;
              padding: 1px 5px;
              vertical-align: middle;
              margin-right: 7px;
            `}
          >
            NEW
          </div>
          Big News! We've announced <Emoji emoji="house_buildings" size={18} />{" "}
          ProcTHOR <ArrowRightOutlined />
        </div>
      </a>
    </>
  );
}

/**
 * This content is displayed above the video on the iTHOR and RoboTHOR overview pages.
 */
export function EnvironmentContext(props: {
  logo: string;
  description: string;
  logoHeight?: number | string;
  maxWidth?: number | string;
}) {
  return (
    <div
      css={css`
        text-align: center;
        width: 90%;
        margin: auto;
        width: min(85%, ${props.maxWidth ? props.maxWidth : "730px"});
      `}
    >
      <img
        src={props.logo}
        css={css`
          height: ${props.logoHeight ? props.logoHeight : "55px"};
          max-width: 100%;
          margin-top: 35px;
        `}
      />
      <div
        css={css`
          color: ${color.gray6};
          line-height: 23px;
          font-size: 16px;
          margin-top: 15px;
          margin-bottom: -15px;
        `}
      >
        {props.description}
      </div>
    </div>
  );
}

export function ExtendedHeader(props: {
  videoLink?: string;
  bottomContent?: React.ReactNode;
  upperContent?: React.ReactNode;
  useBoxShadow?: boolean;
  navigationMaxWidth?: string;
  includeSearch?: boolean;
}) {
  return (
    <div
      css={css`
        background-color: ${color.gray13};
        background-image: url("/media/dark-cover.jpg");
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
      `}
    >
      <Header
        useBoxShadow={props.useBoxShadow}
        navigationMaxWidth={props.navigationMaxWidth}
        includeSearch={false} // {props.includeSearch}
      />
      {props.upperContent}

      {/* middle content contains the video */}
      {props.videoLink === undefined ? (
        <></>
      ) : (
        <div
          css={css`
            text-align: center;
            position: relative;
            overflow: hidden;
            width: 60%;
            padding-top: calc(9 / 16 * min(850px, 60%) + 80px);
            max-width: 850px;
            margin: auto;
          `}
        >
          <iframe
            src={props.videoLink}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
            `}
          />
        </div>
      )}

      {/* bottom content contains things like pip install ai2thor, unity demo, etc. */}
      {props.bottomContent === undefined ? (
        <></>
      ) : (
        <div
          css={css`
            background-color: rgba(0, 0, 0, 0.45);
            color: white;
            text-align: center;
            letter-spacing: 0.5px;
            font-size: 18px;
            padding-top: 15.5px;
            padding-bottom: 21.5px;
          `}
        >
          {props.bottomContent}
        </div>
      )}
    </div>
  );
}

const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 2.8em;
  color: white;
  line-height: 45px;
  @media (max-width: 500px) {
    margin-bottom: 30px;
  }
`;

const PageSubtitle = styled.h2`
  font-size: 16px;
  margin-top: -15px;
  color: ${color.gray5};
  font-weight: 600;
  padding-left: 25px;
  padding-right: 25px;
`;

export function TextHeader(props: {
  title?: string;
  subtitle?: string | React.ReactNode;
  navigationMaxWidth?: string;
  projectSearch?: string;
  metaImage?: string;
  keywords?: string;
  description?: string;
}) {
  return (
    <>
      <SiteHelmet coverImage={props.metaImage}>
        <title>{props.title === undefined ? "AI2-THOR" : props.title}</title>
        {props.keywords ? (
          <meta name="keywords" content={props.keywords} />
        ) : (
          []
        )}
        {props.description ? (
          <meta name="description" content={props.description} />
        ) : (
          []
        )}
      </SiteHelmet>
      {/* page title */}
      {props.title === undefined && props.subtitle === undefined ? (
        <ExtendedHeader
          useBoxShadow={false}
          navigationMaxWidth={props.navigationMaxWidth}
          includeSearch={props.projectSearch !== undefined}
        />
      ) : (
        <ExtendedHeader
          navigationMaxWidth={props.navigationMaxWidth}
          includeSearch={props.projectSearch !== undefined}
          upperContent={
            <>
              <div
                css={css`
                  text-align: center;
                  padding-top: 35px;
                  padding-bottom: 36.5px;
                  padding-left: 15px;
                  padding-right: 25px;
                `}
              >
                <PageTitle>{props.title ? props.title : <></>}</PageTitle>
                <PageSubtitle>
                  {props.subtitle ? props.subtitle : <></>}
                </PageSubtitle>
              </div>
            </>
          }
        />
      )}
    </>
  );
}
